import React from "react";
import Layout from "../components/global/Layout";
import { Container, Box, Heading } from "theme-ui";

import { Search } from "../components";

const SearchPage = () => (
	<Layout>
		<Box as="section">
			<Container px={[3, 4]}>
				<Heading>Search:</Heading>
				<Box py={[3, 4]}>
					<Search />
				</Box>
			</Container>
		</Box>
	</Layout>
);

export default SearchPage;
